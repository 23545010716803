<template>
  <v-container fluid fill-height>
    <v-row no-gutters>
      <v-col lg="7">
        <v-card flat class="pa-0 ma-0 white-wavy-wallpaper background-container card-shadow">
          <v-card class="d-flex primary flex-row align-center">
            <v-icon class="pl-10 pr-6" x-large color="white">{{ icon }}</v-icon>
            <v-card-title class="textLight--text title font-weight-thin">{{ title }}</v-card-title>
          </v-card>
          <v-divider></v-divider>
          <v-row justify="center" no-gutters class="d-flex flex-column">
            <v-col class="ma-0 pl-12 pr-12 pt-6 pb-6" lg="12">
              <v-card-text class="pa-0 ma-0">{{ learn }}</v-card-text>
            </v-col>

            <v-card-actions class="d-flex justify-center pb-8">
              <bz-submit-button width="260" @click="emitNext()" title="Next" />
            </v-card-actions>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BzSubmitButton from "@shared/components/BzSubmitButton";
export default {
  components: {
    BzSubmitButton
  },
  name: "BzModuleCard",
  props: ["title", "learn", "icon"],
  methods: {
    emitNext() {
      this.$emit("nextEmit");
    }
  }
};
</script>

<style></style>
