<template>
  <v-card
    @click="navigateTo(link)"
    :hover="!disabled"
    height="110"
    max-height="110"    
    flat
    :disabled="disabled"
    :class="cardClass"
    style="overflow-y: none;"
  >
    <div class="d-flex justify-space-between full-width align-center">
      <div class="d-flex flex-column mr-4">
        <div class="headline white--text">{{ title }}</div>
        <v-clamp class="font-weight-light" autoresize :max-lines="2">{{ subtitle }}</v-clamp>
      </div>

      <div class="d-flex">
        <bz-active-chip v-if="isActive" active="active" class="mr-1" />
        <v-icon class="arrow" color="white">mdi-apple-keyboard-control</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import BzActiveChip from "./BzActiveChip";
import VClamp from 'vue-clamp';
export default {
  name: "BzCard",

  components: {
    BzActiveChip,
    VClamp
  },
  props: ["title", "isActive", "link", "subtitle", "disabled"],
  computed: {
    cardClass(){
      let style = "d-flex align-center py-10 px-5";

      if (this.disabled){
        style = `secondaryText ${style}`;        
      } else {
        style = `primary ${style}`;  
      }

      return style;
    }
  },
  methods: {
    navigateTo(link) {
      this.$router.push({ name: link });
    }
  }
};
</script>

<style scoped>
.beez-title {
  width: auto;
}

.subtitle-text {
  color: #444444;
  width: auto px;
}
.arrow {
  transform: rotate(90deg);
}
.divider {
  height: 44px;
}
.wrapper {
  width: auto;
}

.active-container {
  width: 100px;
}
</style>
