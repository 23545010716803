<template>
  <v-card max-width="400" height="130" class="mt-2 ml-12 beezin-code">
    <v-row class="ma-2">
      <v-col cols="3" class="d-flex">
        <v-icon class="arrow ml-3 mb-2" size="50" color="primaryLight "
          >mdi-share-variant</v-icon
        >
      </v-col>
      <v-col class="d-flex justify-center flex-column title textDark--text">
        <div class="mt-2">Your Beezin Code is</div>
        <div class="textOrange--text">{{ code }}</div>
        <div class="body-1 mt-3 primaryLight--text">
          <a class="decoration" href="mailto:">Share</a>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "BzShareCard",
  props: ["code"]
};
</script>

<style scoped>
.decoration {
  text-decoration: none;
}
</style>
