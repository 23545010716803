<template>
  <div
    class="white text-capitalize primaryDark--text d-inline px-2 py-1 active-chip title font-weight-light"
  >{{active}}</div>
</template>

<script>
export default {
  props:["active"]
};
</script>

<style  scoped>
.active-chip {
  border-radius: 10px;
}
</style>
