<template>
  <v-container
    v-if="pageLoading"
    fluid
    class="d-flex justify-center align-center onboarding-container"
  >
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </v-container>
  <div v-else class="ma-0 pa-0">
    <v-container v-if="isNew" class="ma-0 pa-0 pl-10 pr-10">
      <bz-module-card
        @nextEmit="isNew = false"
        :title="'Employee On boarding Module'"
        :learn="'Is one of the BeeZBuro modules which is an Organizational specific social media communication tool that fosters engagement between all the stakeholders inside and out side the Organization.'"
        :icon="'mdi-card-account-mail'"
      />
    </v-container>
    <v-container v-else class="ma-0 pa-0 pr-10">
      <v-row>
        <v-col class="pa-0 ma-0" lg="6" sm="12">
          <template v-for="(item,index) in cards">
            <div class="ma-2 pb-2" :key="index">
              <bz-card :title="item.title" :isActive="item.isActive" :link="item.link" />
            </div>
          </template>
        </v-col>

        <v-col class="ml-12">
          <div v-if="checkStatus()">
            <bz-share-card :code="code" />
          </div>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import BzCard from "../../../components/BzCard";
import BzShareCard from "../../../components/BzShareCard";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { eventBus } from "@/main";
import BzModuleCard from "@/beez-admin/components/BzModuleCard";
export default {
  name: "EmployeeOnBoardingModule",
  components: {
    BzCard,
    BzShareCard,
    BzModuleCard
  },
  async created() {
    this.pageLoading = true;
    if (this.configurationsIds.length > 0) {
      this.configurationIds = this.configurationsIds;
    }
    await this.checkActivation();
    this.pageLoading = false;
  },
  computed: {
    ...mapFields("welcome", ["welcomeCard"]),
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("orientation", ["orientationCard"]),
    ...mapFields("introduction", ["introductionCard"]),
    ...mapFields("admin", ["accountModules", "configurationsIds"])
  },
  // watch: {
  //   configurationsIds: {
  //     deep: true,
  //     async handler(value) {
  //       console.log(value, "The Witcher");
  //       this.pageLoading = true;
  //       this.configurationIds = value;
  //       await this.checkActivation();
  //       this.pageLoading = false;
  //     }
  //   }
  // },
  methods: {
    ...mapActions("admin", {
      getAccountModules: "getAccountModules"
    }),
    ...mapActions("admin", {
      getAllAccounts: "getAllAccounts"
    }),
    checkStatus() {
      if (this.code) {
        return true;
      } else {
        return false;
      }
    },
    async checkActivation() {
      let onBoardingIds = {
        welcomeCardId: null,
        cardCustomizationId: null,
        orientationCardId: null
      };
      // let cardCustomizationId,e
      //   orientationCardId,
      //   welcomeCardId = null;
      // const onBoardingData = await this.getOnBoardingModules(
      //   this.currentUser.accounts[0]
      // );
      const accountModules = await this.getAccountModules(
        this.currentUser.accounts[0]
      );
      // console.log(accountModules);
      let employeeOnBoarding;
      for (var modle in accountModules) {
        if (accountModules[modle].moduleName == "Employee Onboarding Module") {
          // console.log("Here");

          employeeOnBoarding = accountModules[modle];
          break;
        }
      }
      if (employeeOnBoarding.cardCustomizationId !== null) {
        this.cards[0].isActive = true;
        this.isNew = false;
      }
      if (employeeOnBoarding.welcomeCardId !== null) {
        this.cards[1].isActive = true;
        this.isNew = false;
      }
      if (employeeOnBoarding.orientationCardId !== null) {
        this.cards[2].isActive = true;
        this.isNew = false;
      }
      if (employeeOnBoarding.status == "ACTIVE") {
        let { beezInCode } = employeeOnBoarding;

        if (beezInCode !== null) {
          this.code = beezInCode;
          eventBus.$emit("addElevationSidebar");
        }
      }
      // console.log(accountModules, "The account onBoardingData");

      //   if (onBoardingData) {
      //     for (var preference in onBoardingData) {
      //       for (var id in this.configurationIds) {
      //         console.log(
      //           onBoardingData[preference].configurationId,
      //           this.configurationIds[id]
      //         );
      //         if (
      //           onBoardingData[preference].configurationId ==
      //           this.configurationIds[id]
      //         ) {
      //           this.isNew = false;
      //           console.log("Found", this.configurationIds[id]);
      //           switch (id) {
      //             case "0":
      //               onBoardingIds.welcomeCardId = this.configurationIds[id];
      //               break;
      //             case "1":
      //               onBoardingIds.cardCustomizationId = this.configurationIds[id];
      //               break;
      //             case "2":
      //               onBoardingIds.orientationCardId = this.configurationIds[id];
      //               break;
      //           }
      //           continue;
      //         }
      //       }
      //     }
      //   }

      //   let allAccounts = await this.getAllAccounts();
      //   // let allUserAccountModules = await this.getUserAccountModules();
      //   // console.log(allAccounts, allUserAccountModules);
      //   if (allAccounts[0].modules.length > 0) {
      //     let { beezInCode } = allAccounts[0].modules[0];

      //     if (beezInCode !== null) {
      //       this.code = beezInCode;
      //     }
      //     eventBus.$emit("addElevationSidebar");
      //   }
      //   if (onBoardingIds) {
      //     onBoardingIds.cardCustomizationId && (this.cards[0].isActive = true);
      //     onBoardingIds.welcomeCardId && (this.cards[1].isActive = true);
      //     onBoardingIds.orientationCardId && (this.cards[2].isActive = true);
      //   }
      //   console.log(onBoardingIds);
    }
  },

  data() {
    return {
      code: "",
      pageLoading: false,
      configurationIds: [],
      isNew: true,
      cards: [
        {
          title: "Introduction BeezCard",
          isActive: false,
          link: "introduction-beezcard"
        },
        {
          title: "Welcome Card",
          isActive: false,
          link: "welcome-card"
        },
        {
          title: "Orientation Card",
          isActive: false,
          link: "orientation-card"
        }
      ]
    };
  }
};
</script>
<style scoped>
.beezin-code {
  box-shadow: 0px 0px 99px #00000036;
}
.onboarding-container {
  min-height: 60vh;
}
</style>


